import html2canvas from 'html2canvas';

const DownloadButton = ({ containerRef }) => {
  const downloadImage = async () => {
    if (!containerRef.current) return;
    
    try {
      // Hide controls before capturing
      const stickers = containerRef.current.querySelectorAll('.sticker-overlay');
      stickers.forEach(sticker => sticker.setAttribute('data-downloading', 'true'));
      
      const canvas = await html2canvas(containerRef.current);
      
      // Show controls after capturing
      stickers.forEach(sticker => sticker.removeAttribute('data-downloading'));
      
      const url = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'edited-image.png';
      link.href = url;
      link.click();
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  return (
    <button
      onClick={downloadImage}
      style={{
        backgroundColor: '#4CAF50',
        color: 'white',
        padding: '12px 24px',
        borderRadius: '6px',
        border: 'none',
        fontSize: '1rem',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
      }}
    >
      Download Image
    </button>
  );
};

export default DownloadButton;
