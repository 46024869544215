import React from 'react';
import example1 from './example1.png';
import example2 from './example2.png';
import example3 from './example3.png';

const ExampleCards = () => {
  const examples = [
    {
      id: 1,
      image: example1,
      title: "Upload"
    },
    {
      id: 2,
      image: example2,
      title: "Add"
    },
    {
      id: 3,
      image: example3,
      title: "Create"
    }
  ];

  return (
    <div style={{
      maxWidth: '1200px',
      margin: '40px auto',
      padding: '20px',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '20px',
      }}>
        {examples.map((example) => (
          <div
            key={example.id}
            style={{
              flex: '1',
              backgroundColor: 'white',
              borderRadius: '12px',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden',
              transition: 'transform 0.3s ease',
              cursor: 'pointer',
              ':hover': {
                transform: 'translateY(-5px)'
              }
            }}
          >
            <div style={{
              width: '100%',
              height: '200px', // Adjust this value as needed
              overflow: 'hidden'
            }}>
              <img
                src={example.image}
                alt={example.title}
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                }}
              />
            </div>
            <div style={{
              padding: '15px',
              textAlign: 'center',
              borderTop: '1px solid #eee'
            }}>
              <h3 style={{
                margin: '0',
                color: '#333',
                fontSize: '1.2rem'
              }}>
                {example.title}
              </h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ExampleCards;
