import React from 'react';
import unibrow1 from './unibrow1.png';
import unibrow2 from './unibrow2.png';
import unibrow3 from './unibrow3.png';
import unibrow4 from './unibrow4.png';
import unibrow5 from './unibrow5.png';
import unibrow6 from './unibrow6.png';
import unibrow7 from './unibrow7.png';

const StickerSelector = ({ onStickerSelect }) => {
  const stickers = [
    { id: 1, src: unibrow1 },
    { id: 2, src: unibrow2 },
    { id: 3, src: unibrow3 },
    { id: 4, src: unibrow4 },
    { id: 5, src: unibrow5 },
    { id: 6, src: unibrow6 },
    { id: 7, src: unibrow7 },
  ];

  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))', // Changed from 120px to 80px
      gap: '10px', // Reduced gap from 15px to 10px
      padding: '10px', // Reduced padding if needed
    }}>
      {stickers.map((sticker) => (
        <div
          key={sticker.id}
          onClick={() => onStickerSelect(sticker)}
          style={{
            backgroundColor: 'white',
            border: '2px solid #e0e0e0',
            borderRadius: '8px',
            padding: '10px',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            ':hover': {
              transform: 'translateY(-2px)',
              boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              borderColor: '#4CAF50'
            }
          }}
        >
          <img
            src={sticker.src}
            alt={`Unibrow ${sticker.id}`}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              aspectRatio: '1',
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default StickerSelector;
