import React, { useState, useRef } from "react";
import ImageUploader from "./ImageUploader";
import StickerSelector from "./StickerSelector";
import StickerOverlay from "./StickerOverlay";
import DownloadButton from "./DownloadButton";
import background from "./background.jpg";
// import background from "./backgroundhair.png";
import html2canvas from "html2canvas";
import ExampleCards from './ExampleCards';

function App() {
  const [uploadedImage, setUploadedImage] = useState(null);
  const [stickers, setStickers] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const imageContainerRef = useRef(null);

  const handleImageUpload = (file) => {
    const imageUrl = URL.createObjectURL(file);
    setUploadedImage(imageUrl);
  };

  const handleStickerSelect = (sticker) => {
    setStickers((prev) => [
      ...prev,
      {
        id: Date.now(),
        ...sticker,
        position: { x: 10, y: 10 },
      },
    ]);
  };

  const handleDownload = async () => {
    setIsDownloading(true);
    
    setTimeout(async () => {
      try {
        const canvas = await html2canvas(imageContainerRef.current);
        const url = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.download = 'edited-image.png';
        link.href = url;
        link.click();
      } catch (error) {
        console.error('Error downloading image:', error);
      } finally {
        setIsDownloading(false);
      }
    }, 100);
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        padding: "40px 20px",
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'repeat',
        backgroundSize: 'contain',
      }}
    >
      <h1
        style={{
          textAlign: "center",
          fontSize: "4rem",
          fontWeight: "900",
          textTransform: "uppercase",
          color: "white",
          marginBottom: "10px",
          letterSpacing: "8px",
          textShadow: "6px 6px 0px black",
          fontFamily: '"Arial Black", Gadget, sans-serif',
        }}
      >
        Unibrow
      </h1>
      <h2
        style={{
          textAlign: "center",
          fontSize: "2rem",
          fontWeight: "bold",
          color: "#FF69B4",
          marginBottom: "30px",
          letterSpacing: "4px",
          textShadow: "4px 4px 8px rgba(0,0,0,1), -2px -2px 4px rgba(0,0,0,0.5)",
          fontFamily: "Arial, sans-serif",
        }}
      >
        On UniChain
      </h2>
      <div
        style={{
          maxWidth: "800px",
          margin: "0 auto",
          backgroundColor: "white",
          borderRadius: "12px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          padding: "30px",
        }}
      >
        <h1
          style={{
            textAlign: "center",
            color: "#1a1a1a",
            marginBottom: "30px",
            fontSize: "1.2rem",
            fontWeight: "600",
          }}
        >
          Unibrow Image Editor
        </h1>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <ImageUploader onImageUpload={handleImageUpload} />

          {uploadedImage && (
            <>
              <div
                style={{
                  backgroundColor: "#f8f9fa",
                  borderRadius: "8px",
                  padding: "20px",
                }}
              >
                <h2
                  style={{
                    fontSize: "1.2rem",
                    marginBottom: "15px",
                    color: "#333",
                  }}
                >
                  Choose Your Unibrow
                </h2>
                <StickerSelector onStickerSelect={handleStickerSelect} />
              </div>

              <div
                ref={imageContainerRef}
                style={{
                  position: "relative",
                  maxWidth: "800px",
                  margin: "0 auto",
                  borderRadius: "8px",
                  overflow: "hidden",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  src={uploadedImage}
                  alt="Uploaded"
                  style={{
                    width: "100%",
                    display: "block",
                  }}
                />
                {stickers.map(sticker => (
                  <StickerOverlay
                    key={sticker.id}
                    sticker={sticker}
                    position={sticker.position}
                    hideControls={isDownloading}
                    onPositionChange={(newPos) => {
                      setStickers(prev => prev.map(s => 
                        s.id === sticker.id ? {...s, position: newPos} : s
                      ));
                    }}
                    onDelete={() => {
                      setStickers(prev => prev.filter(s => s.id !== sticker.id));
                    }}
                    className="sticker-overlay"
                  />
                ))}
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <button
                  onClick={handleDownload}
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    padding: "12px 24px",
                    borderRadius: "6px",
                    border: "none",
                    fontSize: "1rem",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                    ":hover": {
                      backgroundColor: "#45a049",
                    },
                  }}
                >
                  Download Image
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      
      <ExampleCards />
    </div>
  );
}

export default App;
