import React, { useState, useEffect, useCallback } from 'react';

const StickerOverlay = ({ sticker, position, onPositionChange, onDelete, hideControls }) => {
  const [rotation, setRotation] = useState(0);
  const [size, setSize] = useState({ width: 100, height: 100 });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [resizeDirection, setResizeDirection] = useState(null);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [startSize, setStartSize] = useState({ width: 0, height: 0 });

  const handleKeyPress = useCallback((e) => {
    if (e.key === 'r' || e.key === 'R') {
      setRotation(prev => (prev + 15) % 360);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);

  // Handle both mouse and touch events
  const getEventCoordinates = (e) => {
    if (e.touches) {
      return { x: e.touches[0].clientX, y: e.touches[0].clientY };
    }
    return { x: e.clientX, y: e.clientY };
  };

  const handleStart = (e) => {
    if (isResizing) return;
    
    const coords = getEventCoordinates(e);
    setIsDragging(true);
    setStartPos({
      x: coords.x - position.x,
      y: coords.y - position.y
    });
  };

  const handleMove = (e) => {
    if (!isDragging && !isResizing) return;
    
    const coords = getEventCoordinates(e);
    
    if (isResizing) {
      const deltaX = coords.x - startPos.x;
      const deltaY = coords.y - startPos.y;

      switch (resizeDirection) {
        case 'e':
          setSize({ ...size, width: Math.max(20, startSize.width + deltaX) });
          break;
        case 's':
          setSize({ ...size, height: Math.max(20, startSize.height + deltaY) });
          break;
        case 'se':
          setSize({
            width: Math.max(20, startSize.width + deltaX),
            height: Math.max(20, startSize.height + deltaY)
          });
          break;
        case 'w':
          setSize({ ...size, width: Math.max(20, startSize.width - deltaX) });
          break;
        case 'n':
          setSize({ ...size, height: Math.max(20, startSize.height - deltaY) });
          break;
        default:
          break;
      }
    } else if (isDragging) {
      onPositionChange({
        x: coords.x - startPos.x,
        y: coords.y - startPos.y
      });
    }
  };

  const handleEnd = () => {
    setIsDragging(false);
    setIsResizing(false);
  };

  // Add touch event listeners
  useEffect(() => {
    const element = document.documentElement;
    
    if (isDragging || isResizing) {
      element.addEventListener('touchmove', handleMove, { passive: false });
      element.addEventListener('touchend', handleEnd);
      element.addEventListener('mousemove', handleMove);
      element.addEventListener('mouseup', handleEnd);

      // Prevent scrolling while dragging on mobile
      const preventDefault = (e) => e.preventDefault();
      element.addEventListener('touchmove', preventDefault, { passive: false });

      return () => {
        element.removeEventListener('touchmove', handleMove);
        element.removeEventListener('touchend', handleEnd);
        element.removeEventListener('mousemove', handleMove);
        element.removeEventListener('mouseup', handleEnd);
        element.removeEventListener('touchmove', preventDefault);
      };
    }
  }, [isDragging, isResizing, startPos, startSize]);

  const handleResizeStart = (e, direction) => {
    e.stopPropagation();
    setIsResizing(true);
    setResizeDirection(direction);
    setStartPos({ x: e.clientX, y: e.clientY });
    setStartSize({ ...size });
  };

  const resizeHandleStyle = (cursor) => ({
    position: 'absolute',
    width: '10px',
    height: '10px',
    background: 'white',
    border: '1px solid #666',
    borderRadius: '50%',
    cursor: cursor,
    zIndex: 1000,
  });

  const rotateClockwise = () => {
    setRotation(prev => (prev + 15) % 360);
  };

  const rotateCounterClockwise = () => {
    setRotation(prev => (prev - 15 + 360) % 360);
  };

  const handleTouchStart = (e) => {
    e.preventDefault(); // Prevent default touch behavior
    const touch = e.touches[0];
    setIsDragging(true);
    setStartPos({
      x: touch.clientX - position.x,
      y: touch.clientY - position.y
    });
  };

  const handleTouchMove = (e) => {
    e.preventDefault(); // Prevent scrolling while dragging
    if (!isDragging) return;

    const touch = e.touches[0];
    onPositionChange({
      x: touch.clientX - startPos.x,
      y: touch.clientY - startPos.y
    });
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  // Clean version for download
  if (hideControls) {
    return (
      <img
        src={sticker.src}
        alt="Sticker"
        style={{
          position: 'absolute',
          left: position.x,
          top: position.y,
          width: size.width,
          height: size.height,
          transform: `rotate(${rotation}deg)`,
          userSelect: 'none',
          pointerEvents: 'none'
        }}
      />
    );
  }

  return (
    <div
      style={{
        position: 'absolute',
        left: position.x,
        top: position.y,
        transform: `rotate(${rotation}deg)`,
        cursor: isDragging ? 'grabbing' : 'grab',
        userSelect: 'none',
        touchAction: 'none', // Disable default touch actions
      }}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <img
        src={sticker.src}
        alt="Sticker"
        style={{
          width: size.width,
          height: size.height,
          userSelect: 'none',
          touchAction: 'none',
          WebkitUserSelect: 'none', // Add these additional properties
          WebkitTouchCallout: 'none',
          WebkitTapHighlightColor: 'transparent',
        }}
      />

      {/* Rotation controls */}
      <div style={{
        position: 'absolute',
        top: '-40px',
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        gap: '10px',
        zIndex: 1000,
      }}>
        <button
          onClick={() => setRotation(prev => (prev - 15 + 360) % 360)}
          style={{
            background: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '35px', // Increased size for better touch
            height: '35px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            padding: 0,
            touchAction: 'manipulation',
          }}
        >
          ↺
        </button>
        <button
          onClick={() => setRotation(prev => (prev + 15) % 360)}
          style={{
            background: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '50%',
            width: '35px', // Increased size for better touch
            height: '35px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '20px',
            padding: 0,
            touchAction: 'manipulation',
          }}
        >
          ↻
        </button>
      </div>

      {/* Delete button */}
      <button
        onClick={onDelete}
        style={{
          position: 'absolute',
          top: '-10px',
          right: '-10px',
          background: 'red',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: '20px',
          height: '20px',
          cursor: 'pointer',
          zIndex: 1000,
        }}
      >
        ×
      </button>

      {/* Resize handles */}
      <div
        onMouseDown={(e) => handleResizeStart(e, 'e')}
        style={{
          ...resizeHandleStyle('e-resize'),
          right: '-5px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
      <div
        onMouseDown={(e) => handleResizeStart(e, 's')}
        style={{
          ...resizeHandleStyle('s-resize'),
          bottom: '-5px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
      <div
        onMouseDown={(e) => handleResizeStart(e, 'se')}
        style={{
          ...resizeHandleStyle('se-resize'),
          bottom: '-5px',
          right: '-5px',
        }}
      />
      <div
        onMouseDown={(e) => handleResizeStart(e, 'w')}
        style={{
          ...resizeHandleStyle('w-resize'),
          left: '-5px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
      <div
        onMouseDown={(e) => handleResizeStart(e, 'n')}
        style={{
          ...resizeHandleStyle('n-resize'),
          top: '-5px',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      />
    </div>
  );
};

export default StickerOverlay;
