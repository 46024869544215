import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUploader = ({ onImageUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles[0]) {
      onImageUpload(acceptedFiles[0]);
    }
  }, [onImageUpload]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png']
    },
    multiple: false
  });

  return (
    <div
      {...getRootProps()}
      style={{
        border: '2px dashed #cccccc',
        borderRadius: '4px',
        padding: '20px',
        textAlign: 'center',
        background: isDragActive ? '#e6f7ff' : '#fafafa',
        cursor: 'pointer',
        margin: '20px auto',
        maxWidth: '500px',
        transition: 'all 0.3s ease',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
        hover: {
          borderColor: '#40a9ff',
          background: '#e6f7ff'
        }
      }}
    >
      <input {...getInputProps()} />
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '10px'
      }}>
        <svg 
          style={{
            width: '40px',
            height: '40px',
            color: '#1890ff'
          }}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
        >
          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
          <polyline points="17 8 12 3 7 8" />
          <line x1="12" y1="3" x2="12" y2="15" />
        </svg>
        
        <div style={{ color: '#666' }}>
          {isDragActive ? (
            <p>Drop the image here ...</p>
          ) : (
            <div>
              <p style={{ margin: '0 0 8px 0', fontSize: '16px' }}>
                Drag & drop an image here, or click to select
              </p>
              <p style={{ margin: 0, fontSize: '14px', color: '#888' }}>
                Supports: JPG, JPEG, PNG
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImageUploader;
